import React from "react";

function Hakkimizda() {
  return (
    <div>
      <button
        onClick={() => {
          window.location.href = "https://erzincandanotokiralama.com/";
        }}
      >
        Anasayfaya Git
      </button>
    </div>
  );
}

export default Hakkimizda;
