import SlideShow from "../../components/SlideShow";
import slider1 from "../../assets/arabalar/slider1.png";
import sliderBg from "../../assets/sliderBg.jpg";
import "./homePage.css";
import { ourServices } from "../../helper/ourServices";
import phoneIcon from "../../assets/phone.png";
import whatsappIcon from "../../assets/whatsapp.png";
import ReservationForm from "../../components/reservationForm";

function HomePage() {
  var formData = new FormData();
  formData.append("car_brand", "asdasd");
  formData.append("car_model", "asdasd");
  formData.append("car_year", "asdasd");
  // useEffect(() => {
  //   axios.post("https://api.erzincandanotokiralama.com/islem.php", formData, {
  //     header: {
  //       "Access-Control-Allow-Origin": "http://localhost:3000",
  //     },
  //   });
  //   // fetch("https://api.erzincandanotokiralama.com/islem.php", {
  //   //   method: "POST",
  //   //   body: data,
  //   // });
  // }, {}); //eslint-disable-line
  const images = [slider1, slider1];
  return (
    <div className="d-flex flex-d-col width-70 align-center full-w home-page">
      <div className="homePage-slide-container">
        <SlideShow images={images} background={sliderBg} />
      </div>
      <ReservationForm />
      <div className="d-grid width-70 homepage-communition justify-center align-center">
        <a href="tel:+905427711949">
          <div className="communition-button">
            <img className="communition-logo" src={phoneIcon} alt="telefon" />
            Hemen Ara
          </div>
        </a>
        <a href="https://wa.me/+905427711949">
          <div className="communition-button">
            <img
              className="communition-logo"
              src={whatsappIcon}
              alt="telefon"
            />
            Whatsapp'la İletişime Geç
          </div>
        </a>
      </div>
      <div className="width-70 page-content">
        <div className="page-title">
          <h2>Hizmetlerimiz</h2>
        </div>
        <div className="service-content d-grid">
          {ourServices.map((service) => {
            return (
              <div key={service.id} className="d-flex ">
                <img
                  className="communication-icon"
                  src={service.image}
                  alt="communication"
                />
                <div className="d-flex flex-d-col justify-center">
                  <h3>{service.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: service.desc }}></p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
